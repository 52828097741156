import { useContext, useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { useDebounce } from "usehooks-ts";
import { getEventsResume } from "../api/event";
import DashboardCard from "../components/cards/DashboardCard";
import Announcements from "../components/common/Announcements";
import SectionHeading from "../components/common/SectionHeading";
import ConfirmationModal from "../components/modals/ConfirmationModal";
import Layout from "../components/navigation/Layout";
import TabsBtn from "../components/navigation/NavTabs";
import { AppContext } from "../contexts/AppContext";
import { UserContext } from "../contexts/UserContext";
import { useQueryParams } from "../hooks/useQueryParams";
import trad from "../lang/traduction";
import { IConfirmationModalConfig } from "../types/Modals";

function Dashboard() {
	const { lang } = useContext(AppContext);
	const { user } = useContext(UserContext);
	const [confirmModalConfig, setConfirmModalConfig] =
		useState<IConfirmationModalConfig>();

	const { getQueryParam, setQueryParam } = useQueryParams({
		s: "",
		tab: "future"
	});
	const [search, setSearch] = useState(getQueryParam("s"));
	const debouncedValue = useDebounce<string>(search, 500);

	const {
		data = [],
		isLoading,
		error,
		refetch
	} = useQuery({
		queryKey: ["resume", getQueryParam("tab"), getQueryParam("s"), , user],
		queryFn: ({ signal }) =>
			getEventsResume(
				user?.IDPersonne,
				getQueryParam("tab"),
				getQueryParam("s"),
				signal
			),
		refetchOnWindowFocus: false
	});

	useEffect(() => {
		setQueryParam("s", debouncedValue);
	}, [debouncedValue]);

	const LinkToCreateEvent = () => (
		<Link
			to="/event-configuration"
			className="flex h-full w-full cursor-pointer flex-row items-center rounded-md border border-primary bg-white py-2 px-3 text-center text-xs font-normal uppercase text-primary duration-150 hover:bg-primary hover:text-white sm:my-2 sm:w-fit"
		>
			{trad[lang].create_event}
		</Link>
	);

	console.log(data);

	return (
		<Layout active_key="dashboard">
			<div className="flex h-screen w-full flex-col items-center overflow-y-auto p-5">
				<Announcements />

				{/* Title */}
				<h1 className="mb-6 mt-2 text-2xl font-bold text-gloom md:mb-8 md:text-3xl">
					{trad[lang].dashboard}
				</h1>

				{/* Tabs */}
				<TabsBtn
					selectedTab={getQueryParam("tab")}
					setSelectedTab={(e) => setQueryParam("tab", e)}
				/>

				{/* Section heading */}
				<SectionHeading
					title={trad[lang].yourEvents}
					count={data?.length || 0}
					searchQuery={search}
					setSearchQuery={setSearch}
					searchTrad={trad[lang].search_event}
					middleComponent={<LinkToCreateEvent />}
				/>

				{/* Cards */}
				<div className="flex w-full flex-col items-center gap-6 pb-8">
					{isLoading ? (
						<div className="flex items-center">
							{trad[lang].loading}
							<AiOutlineLoading3Quarters className="ml-2 animate-spin" />
						</div>
					) : (
						data?.map((event: any) => (
							<DashboardCard
								event={event}
								setConfirmModalConfig={setConfirmModalConfig}
								refetch={refetch}
							/>
						))
					)}
				</div>

				{confirmModalConfig && confirmModalConfig.opened === true && (
					<ConfirmationModal config={confirmModalConfig} />
				)}
			</div>
		</Layout>
	);
}

export default Dashboard;
